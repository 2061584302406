import React from "react";
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  Box,
  createStyles,
} from "@material-ui/core";
import { Link } from "gatsby";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";

import { link, findRoots, findMyChildren } from "./util";

const useStyles = makeStyles((theme) =>
  createStyles({
    link: {
      textDecoration: "none",
      color: theme.palette.text.primary,
    },
    accordion: {
      boxShadow: "none",
      backgroundColor: "transparent",
    },
  })
);

const FullList = ({ options, menuData }) => {
  const classes = useStyles();

  const recursiveMapper = (arr, level = 0) => (item, index) => {
    const childrenOfItem = findMyChildren(arr, item.node.id);
    const menuId = `menu-${level}`;

    if (childrenOfItem.length > 0) {
      if (level === 0) {
        return (
          <Box>
            <Accordion className={classes.accordion}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2d-content"
                id="panel1d-header"
              >
                <Typography>{item.node.menuText}</Typography>
              </AccordionSummary>

              <AccordionDetails>
                <Box id={menuId}>
                  {childrenOfItem.map(recursiveMapper(arr, level))}
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
        );
      }
    }
    //in case there is no dropdown
    if (level === 0) {
      return (
        <Box>
          <List>
            <Link
              className={classes.link}
              to={link(item.node.name, item.node.pathPrefix)}
            >
              <ListItem>
                <ListItemText>
                  <Typography>{item.node.menuText}</Typography>
                </ListItemText>
              </ListItem>
            </Link>
          </List>
        </Box>
      );
    }
  };

  return <>{findRoots(menuData, options).map(recursiveMapper(menuData))}</>;
};

export default FullList;
