export const link = (name, pathPrefix) =>
  name === "index"
    ? "/"
    : pathPrefix
    ? `/${pathPrefix}` + `/${name}`
    : `/${name}`;

export const findRoots = (arr, options) => {
  const availableIds = options.map((option) => option.id);
  const unsortedList = arr.filter(
    (item) =>
      item.node.parentPage === null &&
      item.node.available === true &&
      availableIds.includes(item.node.id)
  );
  return unsortedList.sort((a, b) => (a.node.order > b.node.order ? 1 : -1));
};

export const findMyChildren = (arr, id) => {
  return arr.filter(
    (item) => item?.node?.parentPage?.id === id && item.node.available === true
  );
};
