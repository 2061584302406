import React from 'react';
import Img from 'gatsby-image';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';
import Button from '../../components/Button';

const useStyles = props =>
  makeStyles(theme => ({
    wrapper: {
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
    link: {
      fontWeight:'bold',
      lineHeight: '1.3',
      textDecoration: 'none',
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.primary.main,
      },
      '&:active': {
        color: theme.palette.primary.main,
      },
      '&:link': {
        color: theme.palette.secondary.black,
        '&:hover':{
          color:theme.palette.primary.main,
        },     
      },
      '&:visited': {
        color: theme.palette.secondary.black,
      },
      '&:focus': {
        color: theme.palette.primary.main,
      },
    },
    logo: {
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
    },
    mail: {
      textDecoration: 'none',
      fontWeight: 'bold',
      color: theme.palette.text.primary,
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    titles: {
      fontWeight: 600,
    },
    logoBox:{
      [theme.breakpoints.down('sm')]: {
        marginLeft: '2rem',
      }
    },
    buttonContainer:{
      marginLeft:'-3%',
      marginTop:'10px',
      [theme.breakpoints.down('md')]: {
        justifyContent:'center',
        marginLeft: '0px',
        paddingBottom:'2rem',
      },
    },  
  }));

const Footer = props => {
  const data = props.data[props.componentId];
  const classes = useStyles(props)();
  const available = option => option.available;
  const link = name => (name === 'index' ? '/' : `/${name}`);

  return (
    <Box bgcolor="secondary.dark" py={5}>
      <Container maxWidth="lg">
        <Grid container spacing={2} className={classes.wrapper}>
 
        

          <Grid item container xs={12} sm={6} lg={4} alignItems="flex-start" className={classes.logo}>
          <Box display='flex' flexDirection='column'>
            <Box width="80%" className = {classes.logoBox}>
              <Img fluid={data.logo.fluid} alt="logo" />
            </Box>
            <Box mt={4}>
              <Typography variant='body2'>{data.registeredCharityTitle}</Typography>
              <Typography variant='body2' gutterBottom>{data.registeredCharityNumber}</Typography>
              <Typography variant='body2'>{data.companyRegistrationTitle}</Typography>
              <Typography variant='body2'>{data.companyRegistrationNumber}</Typography>
            </Box>
          </Box>
          </Grid>



          <Grid item xs={12} sm={6} lg={4}>
            <Box pb={3}>
              <Typography className = {classes.titles} color='primary' variant="h6" component="h3">
                {data.title.toUpperCase()}
              </Typography>
            </Box>
            {data.options.filter(available).map(option => {
              return (
                <Box>
                  <Typography variant="body2" paragraph className={classes.link} onClick={() => props.navigate(link(option.name))} key={option.id}>
                    {option.menuText}
                  </Typography>
                  <Typography variant="body2" paragraph>
                    <a target="blank" className={classes.link} href={option.pdfLink}>
                      {option.menuTitle}
                    </a>  
                  </Typography>
                </Box>
              );
            })}
          </Grid>




          <Grid item xs={12} sm={6} lg={4}>
          <Box  pb={3}>        
            <Typography className = {classes.titles} color='primary' variant="h6" component="h3" gutterBottom>
              {data.title2.toUpperCase()}
            </Typography>
          </Box>

            <Box display="flex" flexDirection="column" alignItems={{ xs: 'center', md: 'flex-start' }}>
                  <Box className= {classes.disclaimerText}>
                    <Typography variant='body2' gutterBottom>{data.address}</Typography>
                    <Typography variant='body2' gutterBottom>{data.city}</Typography>
                    <Typography variant='body2' gutterBottom>{data.postalCode}</Typography>
                    <Typography variant='body2' gutterBottom>{data.country}</Typography>
                  </Box>
                  <Box mt={2}>
                    <a className={classes.mail} href={'mailto:' + data.email}>
                      {data.email}
                    </a>
                </Box>
              </Box>
              <Grid item container className={classes.buttonContainer} lg={6}>
                <Grid item lg={3}>
                  <Button target="_blank" startIcon={<FacebookIcon style={{fontSize: '1.875rem'}} color='primary'/>} href={data.facebookLink}>
                    <Typography></Typography>
                  </Button>
                </Grid>
                <Grid item lg={3}>
                  <Button  target="_blank" startIcon={<TwitterIcon style={{fontSize: '1.875rem'}} color='primary'/>} href={data.twitterLink}>
                    <Typography></Typography>
                  </Button>
                </Grid>
                <Grid item lg={3}>
                  <Button target="_blank" startIcon={<LinkedInIcon style={{fontSize: '1.875rem'}} color='primary'/>} href={data.linkedinLink}>
                    <Typography></Typography>
                  </Button>
                </Grid>
                <Grid item lg={3}>
                  <Button target="_blank" startIcon={<InstagramIcon style={{fontSize: '1.875rem'}} color='primary'/>} href={data.instagramLink}>
                    <Typography></Typography>
                  </Button>
              </Grid>
            </Grid>
          </Grid>
          <Box pt={3}>
          </Box>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
