import React, { useState } from "react";

import { navigate } from "gatsby";
import { makeStyles } from "@material-ui/core/styles";
import { Menu, Button, Box, Zoom } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import { findRoots, findMyChildren } from "./util.js";

const useStyles = makeStyles((theme) => ({
  button: {
    "&.active": {
      background: "black",
    },
  },
  link: {
    fontSize: "0.875rem",
    "&:hover": {
      color: theme.palette.primary.main,
    },
    "&:active": {
      color: theme.palette.primary.main,
    },
  },
  paperLink: {
    borderRadius: "0px",
    "&:hover": {
      color: "#ffffff",
      backgroundColor: " #ce2120de",
    },
  },
  hoverLink: {
    "&:hover": {
      color: "#ffffff",
    },
  },
  transparentMenu: {
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    boxShadow: "none",
  },
}));

const DesktopMenu = (props) => {
  const [anchorEl, setAnchorEl] = useState({});
  const { options, menuData } = props;
  const classes = useStyles();
  const roots = findRoots(menuData, options);

  const handleClick = (id) => (event) => {
    event.persist();
    if (!anchorEl[id]) {
      event.stopPropagation();
    }
    event.preventDefault();
    setAnchorEl((existing) => ({
      ...existing,
      [id]: event.currentTarget,
    }));
  };

  const handleClose = (id) => () => {
    setAnchorEl(() => ({
      [id]: null,
    }));
  };

  const navigateTo = (id, event, page, pathPrefix) => {
    event.preventDefault();
    setAnchorEl((existing) => ({
      ...existing,
      [id]: null,
    }));

    const handleIndex = (page) =>
      page === "index"
        ? "/"
        : pathPrefix
        ? `/${pathPrefix}` + `/${page}`
        : `/${page}`;
    navigate(handleIndex(page));
  };

  const recursiveMapper = (arr, level = 0) => (item, index) => {
    const childrenOfItem = findMyChildren(arr, item.node.id);
    const menuId = `menu-${level}`;
    //if it's root and has dropdown
    if (childrenOfItem.length > 0) {
      if (level === 0) {
        return (
          <Box key={item.node.id}>
            <Button
              endIcon={<ExpandMoreIcon />}
              onClick={handleClick(menuId)}
              className={classes.link}
            >
              {item.node.menuText}
            </Button>
            <Menu
              id={menuId}
              anchorEl={anchorEl[menuId]}
              keepMounted
              open={Boolean(anchorEl[menuId])}
              onClose={handleClose(menuId)}
              TransitionComponent={Zoom}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              classes={{ paper: classes.transparentMenu }}
            >
              {childrenOfItem.map(recursiveMapper(arr, level + 1))}
            </Menu>
          </Box>
        );
      }
      return (
        <Box key={item.node.id}>
          <Button
            endIcon={<ChevronRightIcon />}
            onClick={handleClick(menuId)}
            className={classes.paperLink}
          >
            {item.node.menuText}
          </Button>
          <Menu
            id={menuId}
            key={item.node.id}
            anchorEl={anchorEl[menuId]}
            keepMounted
            open={Boolean(anchorEl[menuId])}
            onClose={handleClose(menuId)}
            TransitionComponent={Zoom}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            classes={{ paper: classes.transparentMenu }}
          >
            {childrenOfItem.map(recursiveMapper(arr, level + 1))}
          </Menu>
        </Box>
      );
    }
    //in case there is no dropdown
    if (level === 0) {
      return (
        <Box key={item.node.id}>
          <Button
            className={classes.link}
            onClick={(event) =>
              navigateTo(menuId, event, item.node.name, item.node.pathPrefix)
            }
          >
            {item.node.menuText}
          </Button>
        </Box>
      );
    }
    return (
      <Box key={item.node.id} className={classes.paperLink}>
        <Button
          className={`${classes.link} ${classes.hoverLink}`}
          onClick={(event) =>
            navigateTo(menuId, event, item.node.name, item.node.pathPrefix)
          }
        >
          {item.node.menuText}
        </Button>
      </Box>
    );
  };
  return (
    <React.Fragment>{roots.map(recursiveMapper(menuData))}</React.Fragment>
  );
};

export default DesktopMenu;
