import React, { useState } from "react";

import { Drawer, IconButton, Box } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles } from "@material-ui/core/styles";
import CancelIcon from "@material-ui/icons/Cancel";

import FullList from "./FullList";
import ButtonGroup from "./ButtonGroup.js";
import Logo from "./Logo";

const useStyles = makeStyles((theme) => ({
  transparentMenu: {
    backgroundColor: "rgba(255, 255, 255, 0.9)",
  },
  drawerHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(2),
  },
}));

const MobileMenu = ({ data, options, menuData, buttons }) => {
  const classes = useStyles();
  const [state, setState] = useState({ right: false });

  const toggleDrawer = (side, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [side]: open });
  };

  return (
    <Box>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={toggleDrawer("right", true)}
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        PaperProps={{ style: { padding: "20px" } }}
        anchor="right"
        open={state.right}
        onClose={toggleDrawer("right", false)}
        classes={{ paper: classes.transparentMenu }}
      >
        <Box className={classes.drawerHeader}>
          <Box mb={3} style={{ width: "50%" }}>
            <Logo image={data.logo.fluid} />
          </Box>
          <Box>
            <IconButton
              onClick={toggleDrawer("right", false)}
              aria-label="cancel"
            >
              <CancelIcon />
            </IconButton>
          </Box>
        </Box>
        <FullList options={options} menuData={menuData} classes={classes} />
        <ButtonGroup buttons={buttons} />
      </Drawer>
    </Box>
  );
};

export default MobileMenu;
