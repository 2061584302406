import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { createStyles, Box } from "@material-ui/core";
import { Link } from "gatsby";

const useStyles = makeStyles((theme) =>
  createStyles({
    buttonsContainer: {
      display: "flex",
      gap: "0.1rem",
    },
    button: {
      padding: "10px 13px",
      borderRadius: "0px",
      fontWeight: 400,
      textAlign: "center",
      textTransform: "uppercase",
      textDecoration: "none",
      minWidth: "7.6rem",
    },
    yellowButton: {
      backgroundColor: theme.palette.button.yellow,
      color: theme.palette.secondary.main,
      "&:hover": {
        backgroundColor: theme.palette.button.yellowLight,
      },
    },
    orangeButton: {
      backgroundColor: theme.palette.button.orange,
      color: theme.palette.secondary.main,
      "&:hover": {
        backgroundColor: theme.palette.button.orangeLight,
      },
    },
    redButton: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
      "&:hover": {
        backgroundColor: theme.palette.primary.light,
      },
    },
  })
);

export default function ButtonGroup({ buttons }) {
  const classes = useStyles();
  const link = (name, pathPrefix) =>
    name === "index" ? "/" : pathPrefix ? `/${pathPrefix}/${name}` : `/${name}`;
  const available = (option) => option.available;

  return (
    <Box className={classes.buttonsContainer}>
      {buttons.filter(available).map((button, index) => {
        return button.pdfLink ? (
          <a
            href={button.pdfLink}
            key={button.id}
            target="_blank"
            rel="noopener noreferrer"
            className={`${classes.button}
            ${
              index === 0
                ? classes.yellowButton
                : index === 1
                ? classes.orangeButton
                : classes.redButton
            }
          `}
          >
            {button?.menuText ? button.menuText : button.menuTitle}
          </a>
        ) : (
          <Link
            to={button?.name ? link(button.name) : "/"}
            key={button.id}
            className={`${classes.button}
            ${
              index === 0
                ? classes.yellowButton
                : index === 1
                ? classes.orangeButton
                : classes.redButton
            }
          `}
          >
            {button?.menuText ? button.menuText : button.menuTitle}
          </Link>
        );
      })}
    </Box>
  );
}
