import React from "react";

import { Link } from "gatsby";
import Img from "gatsby-image";

export default function Logo({ image }) {
  return (
    <Link to="/">
      <Img fluid={image} alt="logo" />
    </Link>
  );
}
