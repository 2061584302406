import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { createStyles, Box, Container, Hidden } from "@material-ui/core";

import ButtonGroup from "./ButtonGroup";
import DesktopMenu from "./DesktopMenu";
import MobileMenu from "./MobileMenu";
import Logo from "./Logo";

const useStyles = makeStyles((theme) =>
  createStyles({
    navContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      position: "relative",
      "&::after, &::before": {
        content: '""',
        position: "absolute",
        left: 0,
        width: "100%",
        height: "1px",
        backgroundColor: "#D0CECE",
      },
      "&::after": {
        bottom: 10,
      },
      "&::before": {
        bottom: "7px",
      },
    },
    logo: {
      marginRight: "auto",
      width: "150px",
    },
    navItems: {
      display: "flex",
      listStyleType: "none",
      gap: "0.5rem",
      alignItems: "center",
      padding: 0,
      margin: 0,
    },
  })
);

export default function TopBar(props) {
  const data = props.data[props.componentId];
  const menuData = props.extraData.menuData.data;
  const classes = useStyles();

  const available = (option) => option.available;
  const filteredOptions = data.options.filter(available);

  return (
    <Container maxWidth="lg">
      <nav className={classes.navContainer}>
        <Box className={classes.logo}>
          <Logo image={data.logo.fluid} />
        </Box>
        <Hidden smDown>
          <Box component="ul" className={classes.navItems}>
            <DesktopMenu
              options={filteredOptions}
              menuData={menuData}
              classes={classes}
            />
            <ButtonGroup buttons={data.button} />
          </Box>
        </Hidden>
        <Hidden mdUp>
          <MobileMenu
            data={data}
            options={filteredOptions}
            menuData={menuData}
            buttons={data.button}
          />
        </Hidden>
      </nav>
    </Container>
  );
}
